export default function usePayment(booking) {
  const { feeResolver } = usePaymentMethod()

  const { realPayment, prepayAmount, maxRewardUseRate } = booking

  const usedDepositResolver = () => {
    const { realPayment, prepayAmount, payByMapPay, remainDeposit, usedDeposit } = booking
    if (payByMapPay) return usedDeposit
    return Math.min(remainDeposit, realPayment - prepayAmount)
  }
  const usedRewardResolver = (amount, originAmount) => {
    if (booking.payByMapPay) return booking.usedReward
    const maxAvailableRewards = Math.min(Math.floor(originAmount * maxRewardUseRate), amount)
    return Math.min(booking.remainReward, maxAvailableRewards)
  }

  const usedDeposit = usedDepositResolver()
  const subTotal = realPayment - prepayAmount - usedDeposit
  const usedReward = usedRewardResolver(subTotal, realPayment)
  const fee = feeResolver(subTotal - usedReward)
  const finalPayment = subTotal - usedReward + fee
  const paymentExcludeFee = subTotal - usedReward

  return {
    subTotal,
    paymentExcludeFee,
    finalPayment,
    usedDeposit,
    maxRewardUseRate,
    usedReward,
    fee
  }
}
