import bookingRequest from 'request/common/booking'
import bookingDetailFormatter from './formatter'
import prepayConfigRequest from 'request/user-booking/prepay-config'
import depositConfigRequest from 'request/user-booking/deposit-config'

export default function useUserBooking() {
  const t = useI18n()
  const { user } = useCurrent()
  const { bookingStateDic, discountLimits } = useBookingConfig()
  const { state, mutate } = useStatic({
    booking: null,
    prepayPolicy: []
  })

  useEffect(() => {
    if (!user) return
    if (!state.booking) return
    overrideBooking(state.booking)
  }, [user, state.booking])

  const openPaymentMethod = () => h.openPaymentMethod({
    type: 'checkoutBooking',
    path: window.location.pathname,
    source: { bookingId: state.booking.id }
  }, 'checkoutBooking')

  const refreshBooking = async bookingId => {
    const booking = await bookingRequest(bookingId)
    bookingDetailFormatter({
      booking,
      bookingStateDic,
      discountLimits,
      t
    })
    state.prepayPolicy = await prepayConfigRequest(booking.prepayAmount)
    state.booking = booking

    mutate()
  }

  const overrideBooking = async booking => {
    if (booking.state !== 2) return
    if (booking.payByMapPay) return
    const { mapPayRewards, remainDeposit } = await depositConfigRequest(booking.stylist.id)
    booking.remainReward = mapPayRewards
    booking.remainDeposit = remainDeposit
  }

  const getBooking = () => state.booking

  return {
    prepayPolicy: state.prepayPolicy,
    getBooking,
    openPaymentMethod,
    refreshBooking
  }
}
